import request from '@/utils/request'

const shopSkusDetail = (sku) => {
  return request({
    url: `/shop/skus/${sku}`,
    method: 'get'
  })
}

export {
  shopSkusDetail
}
