const mixinFormat = {
  methods: {
    outPutDollars (number) {
      if (number.length <= 3) {
        return (number === '' ? '0' : number)
      } else {
        var mod = number.length % 3
        var output = (mod === 0 ? '' : (number.substring(0, mod)))
        for (let i = 0; i < Math.floor(number.length / 3); i++) {
          if ((mod === 0) && (i === 0)) {
            output += number.substring(mod + 3 * i, mod + 3 * i + 3)
          } else {
            output += ',' + number.substring(mod + 3 * i, mod + 3 * i + 3)
          }
        }
        return (output)
      }
    },
    outPutCents (amount) {
      amount = Math.round(((amount) - Math.floor(amount)) * 100)
      return (amount < 10 ? '.0' + amount : '.' + amount)
    },
    numberFormat (number) {
      // number = number.replace(/,/g, '')
      if (isNaN(number) || number === '') return ''
      number = Math.round(number * 100) / 100
      if (number < 0) {
        return '-' + this.outPutDollars(Math.floor(Math.abs(number) - 0) + '') + this.outPutCents(Math.abs(number) - 0)
      } else {
        return this.outPutDollars(Math.floor(number - 0) + '') + this.outPutCents(number - 0)
      }
    }
  }
}
export {
  mixinFormat
}
