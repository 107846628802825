<template>
  <!-- 详情页 -->
  <div class="detail main mt10">
    <div class="goods">
      <!-- 商品图片 -->
      <div class="pics">
        <div class="img" v-if="product.pic">
          <img :src="getPhoto(product.picDef)" width="100%" />
        </div>
        <ul class="img-list">
          <li class="arrow" @click="toLeft">
            <i class="iconfont icon-zuoqiehuanjiantou" />
          </li>
          <ul class="ing-list-box">
            <li
              class="img-item"
              v-for="(item, index) in product.picList"
              :key="index"
              @mouseenter="
                product.picDef = item;
                activeImg = index;
              "
            >
              <img :src="getPhoto(item)" width="100%" />
            </li>
          </ul>
          <li class="arrow" @click="toRight">
            <i class="iconfont icon-youqiehuanjiantou" />
          </li>
        </ul>
      </div>
      <!-- 商品价格信息 -->
      <div class="info">
        <p class="title">{{ product.name }}</p>
        <div class="price-box">
          <p class="old-price">
            <span class="price-title">官方价格：</span>
            <span class="old-price-value">￥{{ numberFormat(product.unitPrice) }}</span>
          </p>
          <p class="new-price">
            <span class="price-title">采购价格：</span>
            <span class="new-price-value">￥{{ numberFormat(product.unitPriceShow) }}</span>
          </p>
        </div>
        <div class="sort">
          <span class="sort-title">选择种类</span>
          <ul>
            <li
              :class="sortIndex === index ? 'active' : ''"
              @click="changeSort(item)"
              v-for="(item, index) in sortList"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="address">
          <span class="address-title">配送至</span>
          <!-- <a-cascader style="width: 250px;" :options="options" @change="onChange" placeholder="选择收货地址" /> -->
          <!-- <expertAddress style="display: inline-block;width: 250px;" /> -->
          <span class="cargo">有货</span>
          <span class="cargo-num">商品编号：123456789</span>
        </div>
        <a href="javascript:;" class="help">货品将如何运送</a>
        <div class="contact-us">
          <span class="contact-us-title">客服电话</span>
          <span>021-1234567</span>
        </div>
        <!-- 购买按钮 -->
        <div class="btns">
          <a-input-number size="large" :min="1" :max="100" :defaultValue="1" @change="numChange" />
          <button class="buy-now" @click="buyNow">立即购买</button>
          <button class="join-car" @click="goShoppingCart"><i class="iconfont icon-gouwuche mr10 font20" />加入购物车</button>
        </div>
      </div>
    </div>
    <div class="detail-body pt50">
      <!-- 更多商品 -->
      <div class="body-more mr20">
        <ul style="min-height: 592px;">
          <li class="more-title">热销商品</li>
          <a-empty class="mt50" v-if="data.length === 0" />
          <div v-else>
            <li v-for="item in data" :key="item.sku">
              <img :src="getPhoto(item.picDef)" width="100px" height="100px" />
              <div class="more-good-info ml10">
                <p class="title">{{ item.name }}</p>
                <p class="price">￥{{ numberFormat(item.unitPrice) }}</p>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <div class="body-info">
        <div class="tabs">
          <span :class="['tabs-item', { 'active-tab': isActive }]" @click="isActive = true">商品详情</span>
          <span :class="['tabs-item', { 'active-tab': !isActive }]" @click="isActive = false">包装与规格</span>
          <div v-show="!isActive" class="tabs-content">
            <div class="param" v-for="(param, index) in product.param" :key="index">
              <span class="param-key">{{ param.key }}：</span>
              <span class="param-val">{{ param.value }}</span>
            </div>
          </div>
          <div v-show="isActive" class="tabs-content">
            <!-- <div class="param" v-for="(param, index) in paramList" :key="index">
              <span class="param-key">{{param.key}}：</span>
              <span class="param-val">{{param.value}}</span>
            </div>-->
            <div v-html="product.contentPc"></div>
            <div style="display: block;font-size:14px;" v-for="(item, index) in product.make" :key="index">
              <p>{{ index + 1 }}：{{ item }}</p>
            </div>
          </div>
        </div>
        <div class="detail mt20">
          <img v-for="(item, index) in data" :key="index" :src="getPhoto(item.picDef)" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import expertAddress from '@/components/expertAddress'
import { shopSkusDetail } from "@/serve/shop";
// import { addShopCarts } from '@/serve/shopCars'
// import { getRd } from '@/serve/home'
import { mixinFormat } from "@/mixins/mixin";

import global from "global";
export default {
  name: "detail",
  components: {
    // expertAddress
  },
  mixins: [mixinFormat],
  data() {
    return {
      data: [],
      imgs: [
        // { url: require('../../assets/imgs/1.jpg') },
        // { url: require('../../assets/imgs/2.jpg') },
        // { url: require('../../assets/imgs/3.jpg') },
        // { url: require('../../assets/imgs/4.jpg') }
      ],
      index: -1,
      sortIndex: -1,
      sortList: [{ name: "8色装" }, { name: "10色装" }, { name: "16色装" }],
      isActive: true, // 商品参数是否激活
      productId: "",
      product: {}, // 商品详情
      productNum: 1 // 商品数量
    };
  },
  methods: {
    // 热销商品数据 且只展示5条
    // async getHotGoods () {
    //   getRd({ page: 0, rows: 5 }).then(res => {
    //     res.forEach(item => {
    //       shopSkusDetail(item).then(response => {
    //         this.data.push(response)
    //       })
    //     })
    //   })
    // },
    toLeft() {
      if (this.index > this.imgs.length - 6) return;
      this.index++;
      let ary = document.getElementsByClassName("img-item");
      for (let i = 0; i <= this.index; i++) {
        ary[i].style.display = "none";
      }
    },
    toRight() {
      if (this.index < 0) return;
      this.index--;
      let ary = document.getElementsByClassName("img-item");
      for (let i = 0; i <= this.index; i++) {
        ary[i].style.display = "block";
      }
    },
    // 获取图片
    getPhoto(pics) {
      let tempList = pics.split(",");
      return global.file.url + "/sys/fs/" + tempList[0];
    },
    // 立即购买
    buyNow() {
      // this.$router.push({ path: '/orders', query: { skuId: this.product.sku, num: this.productNum } })
    },
    // 加入购物车
    goShoppingCart() {
      // addShopCarts(this.product.sku, { num: this.productNum }).then(() => {
      //   this.$message.success('加入购物车成功')
      //   this.$router.push({ path: '/shoppingCart' })
      // }).catch(() => {
      //   this.$message.error('操作失败')
      // })
    },
    onChange(value) {
      console.log("value", value);
    },
    // 购买数量
    numChange(val) {
      this.productNum = val;
    },
    // 切换商品类型
    changeSort(item) {
      this.$router.push({ path: item.skus[0] });
      this.$router.go(0);
    },
    // init () {
    //   this.productList.forEach(item => {
    //     if (item.productId === this.productId) {
    //       this.product = item
    //       this.tabsImgs.push({ url: item.pic[0] })
    //     }
    //   })
    // },
    // 获取商品详情
    getShopSkusDetail() {
      shopSkusDetail(this.productId).then(res => {
        this.product = res;
        this.product.picList = res.pic.split(",");
        this.product.picList.unshift(res.picDef);
      });
    }
  },
  created() {
    this.productId = this.$route.params.id;
    // this.init()
    this.getShopSkusDetail();
    // this.getHotGoods()
  }
};
</script>
<style lang="less" scoped>
.detail {
  .goods {
    display: flex;
    .pics {
      width: 400px;
      height: 400px;
      margin-right: 30px;
      .img {
        height: 100%;
        overflow: hidden;
      }
      .img-list {
        padding-left: 0;
        // width: 200%;
        height: 80px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // overflow: hidden;
        li {
          display: inline-block;
        }

        .arrow {
          cursor: pointer;
          img {
            width: 20px;
          }
        }
        .ing-list-box {
          padding-left: 0;
          width: 200%;
          overflow: hidden;
          height: 100%;
          padding-top: 5px;
          .img-item {
            width: 70px;
            height: 70px;
            overflow: hidden;
            border: 1px solid #ccc;
            margin: 0 10px;
          }
        }
      }
    }
    .info {
      width: 530px;
      .title {
        font-size: 16px;
        font-weight: 700;
      }
      .price-box {
        padding: 20px 10px;
        background: #f5f4f4;
        .old-price,
        .new-price {
          .price-title {
            color: #a2a2a2;
          }
          .old-price-value {
            font-size: 16px;
            font-weight: 700;
            color: #a5a3a3;
            text-decoration-line: line-through;
          }
          .new-price-value {
            font-size: 24px;
            font-weight: 700;
            color: #e84a4a;
          }
        }
      }
      .sort {
        border-bottom: 1px solid #ccc;
        ul {
          display: inline-block;
          padding-left: 0px;
          margin: 0;
          li {
            display: inline-block;
            border: 1px solid #ccc;
            padding: 5px 10px;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
              border-color: #e69b47;
              color: #e69b47;
            }
          }
          .active {
            border-color: #e69b47;
            color: #e69b47;
          }
        }
      }
      .address {
        .cargo {
          padding: 0 10px;
          border-right: 1px solid #ccc;
        }
        .cargo-num {
          padding-left: 10px;
        }
      }
      .contact-us {
        border-bottom: 1px solid #ccc;
      }
      .sort,
      .address,
      .contact-us {
        padding: 20px 10px;
      }
      .sort .sort-title,
      .address .address-title,
      .contact-us .contact-us-title {
        display: inline-block;
        width: 80px;
        color: #a5a3a3;
      }
      .help {
        margin-left: 90px;
      }

      .btns {
        margin-top: 20px;
        .buy-now,
        .join-car {
          padding: 10px 40px;
          outline: none;
          font-size: 22px;
          margin-left: 20px;
          cursor: pointer;
        }
        .buy-now {
          background: #fff;
          border-color: #e69b47;
          color: #e69b47;
        }
        .join-car {
          background: #e69b47;
          border-color: #e69b47;
          color: #fff;
        }
      }
    }
  }
  .detail-body {
    display: flex;
    // 更多商品
    .body-more {
      width: 300px;
      display: inline-block;
      ul {
        border: 1px solid #ccc;
        padding-left: 0;
        .more-title {
          font-size: 20px;
          text-align: center;
          color: #fff;
          font-weight: 700;
          background: #e69b47;
        }
        li {
          border-bottom: 1px solid #ccc;
          cursor: pointer;
          padding: 5px 10px;
          &:hover .title {
            color: #e69b47;
          }
        }
        img {
          vertical-align: bottom;
        }
        .more-good-info {
          display: inline-block;

          .title {
          }
          .price {
            color: #e84a4a;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }
    }

    .body-info {
      display: inline-block;
      width: 640px;
      .tabs {
        background: #efefef;
        width: 640px;
        font-size: 20px;
        border: 1px solid #ccc;

        .tabs-item {
          padding: 10px 20px;
          display: inline-block;
          cursor: pointer;
        }

        .active-tab {
          background: #fff;
          border-top: 3px solid #e69b47;
        }

        .tabs-content {
          background: #fff;
          padding: 20px;

          .param {
            font-size: 14px;
            display: inline-block;
            width: 25%;
          }
        }

        .detail {
          width: 870px;
        }
      }
    }
  }
}
</style>
